<template>
  <div style="display: flex;justify-content: center;">
    <div class="spinner spinner-primary spinner-sm mr-25"></div>
  </div>
</template>

<script>

export default {
  name: "Td-Auth",
  components: {
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {

  }
};
</script>
<style scoped>
.spinner.spinner-sm:before {
    width: 10rem;
    height: 10rem;
    margin-top: -0.625rem;
}
</style>
